import { useApi, useRouteRef} from "@backstage/core-plugin-api";
import {
    catalogApiRef,
    EntityDisplayName,
    entityRouteParams,
    entityRouteRef,
    getEntityRelations
} from "@backstage/plugin-catalog-react";
import {
    DomainEntity, Entity, GroupEntity,
    RELATION_OWNED_BY
} from "@backstage/catalog-model";

import React, {useEffect, useState} from "react";
import {Avatar, Card, CardActions, CardContent, CardMedia, Grid, Typography} from "@material-ui/core";
import {ItemCardHeader, LinkButton} from "@backstage/core-components";
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import {getRecursiveMembersOf} from "./helper";

const ANNOTATION_TEAM_NAME = 'd-velop.de/team';

export const SystemExplorerCard = (props:  { entity: DomainEntity}) => {
    const catalogApi = useApi(catalogApiRef);
    const catalogEntityRoute = useRouteRef(entityRouteRef);
    const url = catalogEntityRoute(entityRouteParams(props.entity));

    const [owner, setOwner] = useState<Entity>()
    const [people, setPeople] = useState<Entity[]>([])

    useEffect(() => {
        async function getMembers() {
            const ownedByRelations = getEntityRelations(props.entity, RELATION_OWNED_BY);
            const ownerEntity = await catalogApi.getEntityByRef(ownedByRelations[0]);
            if(ownerEntity){
                setOwner(ownerEntity);
            }

            const teamName = props.entity.metadata.annotations?.[ANNOTATION_TEAM_NAME];

            if(teamName) {
                const group = await catalogApi.getEntityByRef({ kind: 'Group', namespace: 'default', name: teamName });
                let members = await getRecursiveMembersOf(group as GroupEntity, catalogApi);

                members = members
                    .filter(member => member?.spec?.profile?.picture !== undefined)
                    .filter(member => member?.metadata.uid !== ownerEntity?.metadata.uid)
                members.sort(() => Math.random()-0.5);
                setPeople(members)
            }
        }
        getMembers().then();
    }, [catalogApi, props.entity])

    return (
        <Card>
            <CardMedia>
                <ItemCardHeader
                    title={<EntityDisplayName entityRef={props.entity} />}
                />
            </CardMedia>
            <CardContent title="Description">
                <Grid container spacing={1} >
                    <Grid item xs={3}>
                        <Typography variant="overline">Owner</Typography>
                        <Typography>
                            <Avatar alt={owner?.metadata.name} src={
                                (owner?.spec?.profile as { picture: string, displayName: string })?.picture
                            } />
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="overline">Members</Typography>
                        {people.length === 0 && <Typography gutterBottom>No members</Typography>}
                        <AvatarGroup max={6}>
                            {people.map( person => {
                                const profile = person?.spec?.profile as { picture: string, displayName: string }
                                return (<Avatar
                                    itemID={person.metadata.uid}
                                    alt={profile?.displayName}
                                    src={profile?.picture}
                                />)
                            })}
                        </AvatarGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="overline">Description</Typography>
                        <Typography>{props.entity.metadata.description || 'No description available' }</Typography>
                    </Grid>
                </Grid>

            </CardContent>
            <CardActions>
                <LinkButton to={`${url}`} color="primary">
                    More Info
                </LinkButton>
            </CardActions>
        </Card>
    );

}
