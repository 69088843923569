import React from 'react';

const DvelopLogo = (props: {
  classes: {
    svg: string;
    path: string;
  };
}) => {

  return (
      <svg
          className={props.classes.svg}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="7 20 200 70"
      >
        <path
            className={props.classes.path}
            d="M48.0327,74.4388a41.50519,41.50519,0,0,1-5.2472,1.1595,40.71082,40.71082,0,0,1-6.63858.5225,17.7,17.7,0,0,1-6.435-1.1017,13.38857,13.38857,0,0,1-4.84152-3.16,13.90542,13.90542,0,0,1-3.0727-4.986,19.01573,19.01573,0,0,1-1.0722-6.5807,22.15362,22.15362,0,0,1,.8982-6.5218,14.27486,14.27486,0,0,1,2.6381-5.0154,11.75319,11.75319,0,0,1,4.2322-3.2178,13.76038,13.76038,0,0,1,5.7397-1.1301,13.12838,13.12838,0,0,1,3.8849.5214,17.44635,17.44635,0,0,1,2.8987,1.1595V31.536l7.0154-1.1595ZM27.9143,60.119a11.00418,11.00418,0,0,0,2.2034,7.2755,7.4977,7.4977,0,0,0,6.0876,2.6386,24.18033,24.18033,0,0,0,2.8699-.145,19.54843,19.54843,0,0,0,1.9421-.3185V52.1759a10.932,10.932,0,0,0-2.4641-1.189,9.6412,9.6412,0,0,0-3.2756-.5509,6.27676,6.27676,0,0,0-5.5946,2.6092A12.43857,12.43857,0,0,0,27.9143,60.119Z"
        />
        <path
            className={props.classes.path}
            d="M63.1145,71.5406a4.31977,4.31977,0,0,1-1.3624,3.3039,4.87954,4.87954,0,0,1-6.5808,0,4.36294,4.36294,0,0,1-1.33338-3.3039A4.46449,4.46449,0,0,1,55.1713,68.206a4.80491,4.80491,0,0,1,6.5808,0A4.41946,4.41946,0,0,1,63.1145,71.5406Z"
        />
        <path
            className={props.classes.path}
            d="M72.4265,75.3671q-2.9106-5.9715-5.9668-13.7123a173.39617,173.39617,0,0,1-5.55978-16.6105H68.3818q.6405,2.55315,1.4839,5.479.8439,2.9283,1.8047,5.8553.96078,2.9301,1.9793,5.6819,1.0191,2.7549,1.8926,4.9577.87225-2.20275,1.8625-4.9577.9891-2.75295,1.9504-5.6819.959-2.9265,1.8336-5.8553.87225-2.9265,1.5134-5.479h7.2489a173.59872,173.59872,0,0,1-5.55918,16.6105q-3.05748,7.74075-5.96742,13.7123Z"
        />
        <path
            className={props.classes.path}
            d="M90.9148,60.3502a19.0428,19.0428,0,0,1,1.1884-7.0148,14.74,14.74,0,0,1,3.1594-5.0155A13.08832,13.08832,0,0,1,99.785,45.305a14.03479,14.03479,0,0,1,5.2178-1.0144q6.2616,0,9.7697,3.8842,3.5067,3.88545,3.50742,11.5962c0,.3869-.01012.821-.02892,1.3046q-.03012.7254-.0873,1.3046H98.162a7.99021,7.99021,0,0,0,2.5797,5.6524q2.28945,2.00115,6.6385,2.0005a21.84339,21.84339,0,0,0,4.6669-.4635,19.20326,19.20326,0,0,0,3.334-.9861l.9277,5.7397a11.22094,11.22094,0,0,1-1.5947.6086,22.43751,22.43751,0,0,1-2.319.5804q-1.3041.2601-2.8121.434a26.59325,26.59325,0,0,1-3.0727.1746,18.60772,18.60772,0,0,1-6.957-1.189,12.93445,12.93445,0,0,1-4.87038-3.3051,13.53307,13.53307,0,0,1-2.841-4.986A20.34929,20.34929,0,0,1,90.9148,60.3502Zm20.3497-3.1305a9.24016,9.24016,0,0,0-.4058-2.7543,6.63167,6.63167,0,0,0-1.1884-2.26,5.61293,5.61293,0,0,0-1.9132-1.5075,6.10631,6.10631,0,0,0-2.6959-.5509,6.2754,6.2754,0,0,0-2.841.6087,6.4831,6.4831,0,0,0-2.05828,1.5936,7.30129,7.30129,0,0,0-1.30462,2.2612,12.25194,12.25194,0,0,0-.6375,2.6092Z"
        />
        <path
            className={props.classes.path}
            d="M142.496,69.047l1.0446,6.3201H122.978V45.0443h7.0154V69.047Z"
        />
        <path
            className={props.classes.path}
            d="M173.40692,60.1768a19.5818,19.5818,0,0,1-1.04332,6.5512,14.49517,14.49517,0,0,1-2.9572,5.0438,13.282,13.282,0,0,1-4.6091,3.2473,14.89337,14.89337,0,0,1-5.9426,1.1595,14.681,14.681,0,0,1-5.9136-1.1595,13.3525,13.3525,0,0,1-4.5803-3.2473,14.85026,14.85026,0,0,1-2.986-5.0438,19.08243,19.08243,0,0,1-1.0722-6.5512,18.84653,18.84653,0,0,1,1.0722-6.5229,14.65665,14.65665,0,0,1,3.0149-5.0143,13.31393,13.31393,0,0,1,4.60922-3.2178,14.84816,14.84816,0,0,1,5.85578-1.1312,15.0674,15.0674,0,0,1,5.8848,1.1312,12.96955,12.96955,0,0,1,4.6091,3.2178,14.92606,14.92606,0,0,1,2.9861,5.0143A18.87431,18.87431,0,0,1,173.40692,60.1768Zm-7.18882,0a11.8338,11.8338,0,0,0-1.9427-7.1599,6.88775,6.88775,0,0,0-10.8419,0,11.82982,11.82982,0,0,0-1.9421,7.1599,12.072,12.072,0,0,0,1.9421,7.2472,6.843,6.843,0,0,0,10.8419,0A12.07605,12.07605,0,0,0,166.2181,60.1768Z"
        />
        <path
            className={props.classes.path}
            d="M177.4492,46.0894a41.5072,41.5072,0,0,1,5.2473-1.1595,40.69832,40.69832,0,0,1,6.6385-.5226,17.70226,17.70226,0,0,1,6.4351,1.1017,13.38831,13.38831,0,0,1,4.8414,3.1601,13.90579,13.90579,0,0,1,3.0728,4.9859,19.01631,19.01631,0,0,1,1.0722,6.5808,22.15227,22.15227,0,0,1-.8982,6.5217,14.275,14.275,0,0,1-2.6381,5.0155,11.75387,11.75387,0,0,1-4.2322,3.2178,13.76243,13.76243,0,0,1-5.7398,1.13,13.13113,13.13113,0,0,1-3.8848-.5213,17.44767,17.44767,0,0,1-2.8988-1.1595V88.9921l-7.0154,1.1595Zm20.1185,14.3198a11.00443,11.00443,0,0,0-2.2034-7.2755,7.498,7.498,0,0,0-6.0877-2.6387,24.17527,24.17527,0,0,0-2.8698.1451,19.52168,19.52168,0,0,0-1.9422.3185V68.3523a10.93886,10.93886,0,0,0,2.4641,1.189,9.644,9.644,0,0,0,3.2756.5508,6.2771,6.2771,0,0,0,5.5947-2.6091A12.43936,12.43936,0,0,0,197.5677,60.4092Z"
        />
      </svg>
  );
};

export default DvelopLogo;
