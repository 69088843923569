import {
    GroupEntity, RELATION_HAS_MEMBER,
    RELATION_PARENT_OF,
    stringifyEntityRef,
    UserEntity
} from "@backstage/catalog-model";
import {CatalogApi, getEntityRelations} from "@backstage/plugin-catalog-react";


export const getRecursiveMembersOf = async (
    group: GroupEntity,
    catalogApi: CatalogApi,
) => {

    const allUsers = new Map<string, boolean>();
    const allGroups = new Map<string, boolean>();

    if(group === undefined) return [];

    let groupQueue = [stringifyEntityRef(group)]

    while (groupQueue.length > 0) {
        groupQueue.forEach(ref => allGroups.set(ref, true));
        const groupResponse = await catalogApi.getEntitiesByRefs({
            entityRefs: groupQueue,
        })
        const groupEntities = groupResponse.items.filter((entity): entity is GroupEntity => entity !== undefined)

        groupEntities.forEach(groupEntity => allGroups.set(stringifyEntityRef(groupEntity), true));

        groupQueue = groupEntities.map(groupEntity => {
            getEntityRelations(groupEntity, RELATION_HAS_MEMBER, {kind: 'User'})
                .filter(member => !allUsers.has(stringifyEntityRef(member)))
                .forEach(user => allUsers.set(stringifyEntityRef(user), true));

            return getEntityRelations(groupEntity, RELATION_PARENT_OF, {kind: 'Group'})
                .filter(child => !allGroups.has(stringifyEntityRef(child)))
                .map(child => stringifyEntityRef(child));
        }).flat()
    }

    const response = await catalogApi.getEntitiesByRefs({
        entityRefs: Array.from(allUsers.keys()),
        filter: {kind: 'User'},
    });

    return response.items.filter((entity): entity is UserEntity => entity !== undefined);
}