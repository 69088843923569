import React, {useEffect, useState} from "react";
import {useApi} from "@backstage/core-plugin-api";
import {catalogApiRef,  getEntityRelations} from "@backstage/plugin-catalog-react";
import {Entity, RELATION_HAS_MEMBER} from "@backstage/catalog-model";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import {Avatar} from "@material-ui/core";

export const MembersPreview = (props: { ownerRef: string }) => {

    const catalogApi = useApi(catalogApiRef);
    const [owner, setOwner] = useState<Entity[]>();

    useEffect(() => {
        async function fetchData() {
            let ref = props.ownerRef;
            if(!ref.startsWith('user:')) {
                ref = `group:${ref}`;
            }
            const e = await catalogApi.getEntityByRef(ref)
            if(e?.kind === 'User') {
                setOwner([e])
            }
            if(e?.kind === 'Group') {
                const members = getEntityRelations(e, RELATION_HAS_MEMBER);
                const memberEntities = await Promise.all(members.map( m => catalogApi.getEntityByRef(m)));
                setOwner(memberEntities.filter((entity: Entity|undefined): entity is Entity => entity !== undefined));
            }
        }
        fetchData().then()
    }, [catalogApi, props.ownerRef, setOwner])

    if(owner?.length === 0) {
        return <div>Loading...</div>
    }

    return (
        <AvatarGroup>
            { owner?.map((o: Entity) => {
                const profile = o.spec?.profile as {displayName: string, picture: string};
                return <Avatar alt={profile.displayName} src={profile.picture} itemID={o.metadata.uid} />
            })}
        </AvatarGroup>
    )
}