import React from 'react';
import {Route} from 'react-router-dom';
import {apiDocsPlugin, ApiExplorerPage} from '@backstage/plugin-api-docs';
import {CatalogEntityPage, CatalogIndexPage, catalogPlugin,} from '@backstage/plugin-catalog';
import {CatalogImportPage, catalogImportPlugin,} from '@backstage/plugin-catalog-import';
import {ScaffolderPage, scaffolderPlugin} from '@backstage/plugin-scaffolder';
import {orgPlugin} from '@backstage/plugin-org';
import {SearchPage} from '@backstage/plugin-search';
import {TechRadarPage} from '@backstage-community/plugin-tech-radar';
import {TechDocsCustomHome, techdocsPlugin, TechDocsReaderPage,} from '@backstage/plugin-techdocs';
import {TechDocsAddons} from '@backstage/plugin-techdocs-react';
import {UserSettingsPage} from '@backstage/plugin-user-settings';
import {apis} from './apis';
import {entityPage} from './components/catalog/EntityPage';
import {searchPage} from './components/search/SearchPage';
import {Root} from './components/Root';

import {AlertDisplay, OAuthRequestDialog, SignInPage,} from '@backstage/core-components';
import {createApp} from '@backstage/app-defaults';
import {AppRouter, FlatRoutes} from '@backstage/core-app-api';
import {CatalogGraphPage} from '@backstage/plugin-catalog-graph';
import {RequirePermission} from '@backstage/plugin-permission-react';
import {catalogEntityCreatePermission} from '@backstage/plugin-catalog-common/alpha';

import {HomepageCompositionRoot, VisitListener} from "@backstage/plugin-home";
import {HomePage} from "./components/home/HomePage";
import {grafanaPlugin} from "@internal/plugin-grafana";
import {GitlabIcon, GrafanaIcon, KubernetesIcon} from "./assets/icons/CustomIcons";
import {EntityKindPicker, EntityOwnerPicker, EntityTagPicker, EntityTypePicker,} from "@backstage/plugin-catalog-react";
import {myColumnFunc} from "./components/catalog/CatalogColumn";
import {TechInsightsScorecardPage} from '@backstage-community/plugin-tech-insights';
import {configApiRef, microsoftAuthApiRef, useApi} from "@backstage/core-plugin-api";
import {ExplorePage} from "@backstage-community/plugin-explore";
import {explorePage} from "./components/explore/ExplorePage";
import {techDocsTabsConfig} from "./components/techdocs/config";
import {ReportIssue} from "@backstage/plugin-techdocs-module-addons-contrib";
import {CustomMermaid} from "./components/techdocs/mermaid";

const app = createApp({
    components: {
        SignInPage: props => (
            <SignInPage
                {...props}
                auto
                providers={
                    useApi(configApiRef).getString('auth.environment') === 'development' ?
                        ['guest'] :
                        [{
                            id: 'microsoft-auth-provider',
                            title: "d.velop",
                            message: "Sign in via d.velop",
                            apiRef: microsoftAuthApiRef,
                        }]
                }
            />
        )
    },
    plugins: [
        grafanaPlugin,
    ],
    apis,
    bindRoutes({bind}) {
        bind(catalogPlugin.externalRoutes, {
            createComponent: scaffolderPlugin.routes.root,
            viewTechDoc: techdocsPlugin.routes.docRoot,
            createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
        });
        bind(apiDocsPlugin.externalRoutes, {
            registerApi: catalogImportPlugin.routes.importPage,
        });
        bind(scaffolderPlugin.externalRoutes, {
            viewTechDoc: techdocsPlugin.routes.docRoot,
            registerComponent: catalogImportPlugin.routes.importPage,
        });
        bind(orgPlugin.externalRoutes, {
            catalogIndex: catalogPlugin.routes.catalogIndex,
        });
        bind(grafanaPlugin.externalRoutes, grafanaPlugin.routes)
    },
    icons: {
        grafana: GrafanaIcon,
        gitlab: GitlabIcon,
        kubernetes: KubernetesIcon,
    }
});

const routes = (
    <FlatRoutes>
        <Route path="/" element={<HomepageCompositionRoot/>}>
            <HomePage/>
        </Route>
        <Route path="/catalog" element={
            <CatalogIndexPage
                filters={
                    <>
                        <EntityKindPicker/>
                        <EntityTypePicker/>
                        <EntityOwnerPicker/>
                        <EntityTagPicker/>
                    </>
                }
                columns={myColumnFunc}
            />
        }/>
        <Route
            path="/catalog/:namespace/:kind/:name"
            element={<CatalogEntityPage/>}
        >
            {entityPage}
        </Route>
        <Route path="/docs" element={<TechDocsCustomHome tabsConfig={techDocsTabsConfig}/>}/>
        <Route
            path="/docs/:namespace/:kind/:name/*"
            element={<TechDocsReaderPage/>}
        >
            <TechDocsAddons>
                <ReportIssue/>
                <CustomMermaid/>
            </TechDocsAddons>
        </Route>
        <Route path="/create" element={
            <ScaffolderPage
                templateFilter={tpl => tpl?.metadata?.tags?.includes('experimental') === false}
                groups={[
                    {
                        title: 'Golden Path',
                        filter: tpl => tpl?.metadata?.tags?.includes('recommended') === true
                    }
                ]}
            />
        }
        />
        <Route path="/api-docs" element={<ApiExplorerPage/>}/>
        <Route path="/tech-radar"
               element={<TechRadarPage width={1500} height={800}/>}
        />
        <Route
            path="/catalog-import"
            element={
                <RequirePermission permission={catalogEntityCreatePermission}>
                    <CatalogImportPage/>
                </RequirePermission>
            }
        />
        <Route path="/search" element={<SearchPage/>}>
            {searchPage}
        </Route>
        <Route path="/settings" element={<UserSettingsPage/>}/>
        <Route path="/catalog-graph" element={<CatalogGraphPage/>}/>
        <Route path="/tech-insights" element={<TechInsightsScorecardPage/>}/>
        <Route path="/explore" element={<ExplorePage/>}>
            {explorePage}
        </Route>
    </FlatRoutes>
);

export default app.createRoot(
    <>
        <AlertDisplay/>
        <OAuthRequestDialog/>
        <AppRouter>
            <VisitListener/>
            <Root>{routes}</Root>
        </AppRouter>
    </>,
);
