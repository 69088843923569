import {CatalogTable, CatalogTableColumnsFunc, CatalogTableRow} from "@backstage/plugin-catalog";
import {TableColumn} from "@backstage/core-components";
import {languages} from "../../assets/icons/LanguageIcons";


class  LanguageIconColumn implements TableColumn<CatalogTableRow>  {
    width= "24px"
    title = "LANG"
    render = ({ entity }: CatalogTableRow) => {
        for (const [language, icon] of languages) {
            if (entity.metadata.tags?.find(v => v === language)) {
                return icon;
            }
        }
        return undefined;
    }
}


export const myColumnFunc: CatalogTableColumnsFunc = (ctx) => {
    switch (ctx.filters.kind?.value) {
        case 'component':
            return [
                CatalogTable.columns.createNameColumn(),
                CatalogTable.columns.createSystemColumn(),
                CatalogTable.columns.createOwnerColumn(),
                CatalogTable.columns.createSpecTypeColumn(),
                new LanguageIconColumn(),
            ];
        default:
            return CatalogTable.defaultColumnsFunc(ctx);
    }
}