import React from "react";
import {Grid, List, ListItemIcon, ListItemText, makeStyles, Typography,} from "@material-ui/core";
import {
    HomePageCompanyLogo,
    HomePageRecentlyVisited,
    HomePageStarredEntities, Tool, WelcomeTitle,
} from "@backstage/plugin-home";
import {HomePageSearchBar} from "@backstage/plugin-search";
import {SearchContextProvider} from "@backstage/plugin-search-react";
import {Content, Header, InfoCard, Link, Page} from "@backstage/core-components";
import DvelopLogo from "../../assets/icons/DvelopLogo";
import {ExploreIcon, MapIcon, RunIcon} from "../../assets/icons/CustomIcons";
import {EntityRefLink} from "@backstage/plugin-catalog-react";

const useLogoStyles = makeStyles(theme => ({
    container: {
        margin: theme.spacing(5, 0),
    },
    svg: {
        width: 'auto',
        height: 100,
    },
    path: {
        fill: '#7df3e1',
    }
}));

const useStyles = makeStyles(theme => ({
    searchBarInput: {
        maxWidth: '60vw',
        margin: 'auto',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '50px',
        boxShadow: theme.shadows[1],
    },
    searchBarOutline: {
        borderStyle: 'none',
    },
    welcomeBox: {
        height: "100%",
    },

    toolkit: {
        display: 'flex',
        textAlign: "center",
        justifyContent: 'center',
        marginTop: '3em',
        marginBottom: '2em',
    },
    tool: {
        margin: theme.spacing(0.5, 1)
    },
    label: {
        fontSize: '0.9em',
        width: '150px',
        marginTop: theme.spacing(1),
        overflowWrap: 'break-word',
        lineHeight: '1.25',
        color: theme.palette.text.secondary,
    },
    icon: {
        width: '64px',
        height: '64px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        boxShadow: theme.shadows[1],
        backgroundColor: theme.palette.background.default,
    }
}));

export const HomePage = () => {
    const classes = useStyles();
    const {svg, path, container} = useLogoStyles();

    const languages = ['German', 'English', 'Spanish', 'Dutch', 'French'];
    const tools = [
        {label: 'Erste Schritte mit Backstage', url: '/docs/default/component/getting-started-guide', icon: <RunIcon />},
        {label: 'Blättere durch unser Ökosystem', url: '/explore', icon: <ExploreIcon />},
        {label: 'App-Landkarte', url: '/docs/default/component/software-landscape', icon: <MapIcon />},
    ];

    return (
        <SearchContextProvider>
            <Page themeId="home">
                <Header
                    title={ <WelcomeTitle language={languages} /> }
                    subtitle="Willkommen zu Backstage, dem d.velop engineering portal"
                    pageTitleOverride="Home"
                />
                <Content>
                    <Grid container spacing={6} justifyContent="center">
                        <HomePageCompanyLogo
                            className={container}
                            logo={<DvelopLogo classes={{svg, path}}/>}
                        />
                        <Grid container item xs={12} justifyContent="center">
                            <HomePageSearchBar
                                InputProps={{
                                    classes: {
                                        root: classes.searchBarInput,
                                        notchedOutline: classes.searchBarOutline
                                    }
                                }}
                                placeholder="Search"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InfoCard title="Hey hey! 👋" className={classes.welcomeBox}>
                                <Typography>
                                    Hier findest du alles was du brauchst, um dich im d.velop engineering zurecht zu finden.
                                </Typography>
                                <Typography gutterBottom>
                                    Wenn du das erste mal hier bist, schau dir doch mal folgendes an:
                                </Typography>
                                <List className={classes.toolkit}>
                                    {tools.map((tool: Tool) => (
                                        <Link className={classes.tool} to={tool.url}>
                                            <ListItemIcon className={classes.icon}>{tool.icon}</ListItemIcon>
                                            <ListItemText className={classes.label} primary={tool.label} />
                                        </Link>
                                    ))}
                                </List>
                                <Typography>
                                    Wenn du Fragen hast, melde dich gerne bei mir:  <br />
                                    <EntityRefLink entityRef={{kind: 'user', namespace: 'default', name: 'tlan'}} />
                                </Typography>
                            </InfoCard>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <HomePageStarredEntities/>
                                </Grid>
                                <Grid item xs={12}>
                                    <HomePageRecentlyVisited
                                        kind="recent"
                                        numVisitsOpen={3}
                                        numVisitsTotal={10}
                                    />
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </Content>
            </Page>
        </SearchContextProvider>
    );
}