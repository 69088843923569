import {grafanaApiRef} from "@internal/plugin-grafana";
import React from "react";
import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
    makeStyles,
    Theme, Typography
} from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import {
    ContentHeader,
    InfoCard,
    LinkButton,
    Progress,
    StatusOK,
    WarningPanel
} from "@backstage/core-components";
import {useApi} from "@backstage/core-plugin-api";
import {useAsync} from "react-use";
import {useEntity} from "@backstage/plugin-catalog-react";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    }
}))

const warningPanel = (message: string) => (
    <WarningPanel title="Could not load alerts" message="There was an error while loading the alerts">
        {message}
    </WarningPanel>
)

const noAlerts = () => {
    const docLink = {
        title: 'Learn more',
        link: '/docs/default/component/getting-started-guide/backstage/features/grafana/'

    }
    return (<InfoCard title="Status" subheader="Current alert status from grafana" deepLink={docLink}>
        <Typography variant="body1">
            No alerts found for this service. Please make sure you have the correct annotations in your <code>catalog-info.yaml</code> file.
        </Typography>

    </InfoCard>)
}

export const AlertList = () => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const grafana = useApi(grafanaApiRef);

    const {entity} = useEntity();


    const {value, loading, error} = useAsync(async () => {
        const annotation = entity.metadata.annotations?.['grafana/alert-label-selector'];
        if (!annotation) {
            return []
        }
        const selector = annotation.split(',').map((item) => item.trim())

        return await grafana.alertsForSelector(selector)

    })

    const handleChange = (panel: string) => (_: any, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false)
    }

    if (loading) {
        return <Progress/>
    }

    if (error) {
        return warningPanel(error.message);
    }

    if (!value || value.length === 0) {
        return noAlerts();
    }

    return (
        <div className={classes.root}>
            <Accordion>
                <AccordionSummary>
                    <ContentHeader title="Status" description="Current alert status from grafana"/>
                </AccordionSummary>
            </Accordion>
            {value.map(alert => (
                <Accordion expanded={expanded === alert.name} onChange={handleChange(alert.name)}>
                    <AccordionSummary expandIcon={<ExpandMore/>}>
                        <StatusOK>
                            {alert.name}
                        </StatusOK>
                    </AccordionSummary>
                    <AccordionDetails>
                        {alert.annotations.summary}
                    </AccordionDetails>
                    <AccordionActions>
                        <LinkButton to={alert.annotations.runbook_url} disabled={alert.annotations.runbook_url !== ""}>
                            Runbook
                        </LinkButton>
                        <LinkButton to={alert.url}>
                            View in Grafana
                        </LinkButton>
                    </AccordionActions>
                </Accordion>
            ))}
        </div>
    )

}