import {TabsConfig} from "@backstage/plugin-techdocs";
import {Entity} from "@backstage/catalog-model";

const isEntity = (entity: Entity, kind: string, name: string) => entity.kind === kind && entity.metadata.name === name;

export const techDocsTabsConfig: TabsConfig = [
    {
        label: 'Recommended reading',
        panels:[
            {
                title: 'Getting started',
                description: 'Recommended reading for getting started as a software engineer at d.velop.',
                panelType: 'DocsCardGrid',
                filterPredicate: entity =>
                    entity.kind === "Component" &&
                    entity.spec?.type !== "library" &&
                    entity.metadata.tags?.includes('recommended') === true
            },
            {
                title: "d.velop developer platform",
                description: "Starte hier um zu lernen, wie die d.velop developer platform funktioniert.",
                panelType: 'DocsCardGrid',
                filterPredicate: entity => isEntity(entity, "Component", "ddp-docs"),
            }
        ]
    },
    {
        label: 'All',
        panels: [
            {
                title: 'All techdocs',
                description: 'All available techdocs for services',
                panelType: 'DocsTable',
                filterPredicate: () => true
            }
        ]
    }
]