import React, {useEffect, useState} from "react";
import {
    catalogApiRef,
    EntityDisplayName,
    EntityRefLink,
    useEntity
} from "@backstage/plugin-catalog-react";
import {Entity, stringifyEntityRef} from "@backstage/catalog-model";
import {useApi} from "@backstage/core-plugin-api";
import {SubvalueCell, Table, TableColumn} from "@backstage/core-components";
import {MembersPreview} from "./MembersPreview";


export const AppsTable = () => {

    const catalogApi = useApi(catalogApiRef);
    const { entity} = useEntity();
    const [apps, setApps] = useState<Entity[]>([]);

    useEffect( () => {
        async function fetchData() {
            const res = await catalogApi.getEntities({filter: {kind: 'component', 'spec.type': 'app', 'spec.system': stringifyEntityRef(entity)}});
            setApps(res.items);
        }
        fetchData().then()
    }, [catalogApi, entity, setApps])

    const columns: TableColumn<Entity>[] = [
        {
            title: 'App',
            searchable: true,
            render: (row: Entity) => <SubvalueCell value={<EntityRefLink entityRef={row} > <EntityDisplayName entityRef={row} /></EntityRefLink>} subvalue={row.metadata.description} /> ,
        },
        {
            title: 'Members',
            render: (row: Entity) => <MembersPreview ownerRef={row.spec?.owner as string} />
        }
    ];

    return (
        <div>
            <Table title="Apps" data={apps} columns={columns} />
        </div>
    );
};