import {useRouteRef} from "@backstage/core-plugin-api";
import {
    EntityDisplayName,
    EntityRefLinks,
    entityRouteParams,
    entityRouteRef,
    getEntityRelations
} from "@backstage/plugin-catalog-react";
import {ComponentEntity, RELATION_OWNED_BY} from "@backstage/catalog-model";
import React from "react";
import {Card, CardActions, CardContent, CardMedia, Grid,  Typography} from "@material-ui/core";
import { ItemCardHeader, LinkButton} from "@backstage/core-components";
import {TechnologyIcon} from "../../assets/icons/LanguageIcons";

export const LibraryExplorerCard = (props:  { entity: ComponentEntity}) => {
    const catalogEntityRoute = useRouteRef(entityRouteRef);
    const url = catalogEntityRoute(entityRouteParams(props.entity));
    const ownedByRelations = getEntityRelations(props.entity, RELATION_OWNED_BY);
    const owner = (
        <EntityRefLinks
            entityRefs={ownedByRelations}
            defaultKind="group"
            color="inherit"
        />
    );


    return (
        <Card>
            <CardMedia>
                <ItemCardHeader
                    title={<EntityDisplayName entityRef={props.entity} />}
                    subtitle={owner}
                />
            </CardMedia>
            <CardContent title="Description">
                <Grid container spacing={3}>
                    <Grid item xs={10}>
                        <Typography variant="h5">Description</Typography>
                        <Typography>{props.entity.metadata.description || 'No description available' }</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <TechnologyIcon tags={props.entity.metadata.tags}/>
                    </Grid>
                </Grid>

            </CardContent>
            <CardActions>
                <LinkButton to={`${url}/docs`} color="primary">
                    Docs
                </LinkButton>
                <LinkButton to={`${url}`} color="primary">
                    More Info
                </LinkButton>
            </CardActions>
        </Card>
    );

}
