import React from "react";
import {
    Content,
    ContentHeader,
    EmptyState,
    ItemCardGrid,
    Progress,
    SupportButton,
    WarningPanel
} from "@backstage/core-components";
import {
    catalogApiRef,
} from "@backstage/plugin-catalog-react";
import {useApi} from "@backstage/core-plugin-api";
import {useAsync} from "react-use";
import {ComponentEntity} from "@backstage/catalog-model";
import {LibraryExplorerCard} from "./LibraryExplorerCard";
import {CsharpIcon, DockerIcon, GolangIcon, NodejsIcon} from "../../assets/icons/LanguageIcons";
import {Box} from "@material-ui/core";
import {GitlabIcon} from "../../assets/icons/CustomIcons";

interface Group {
    title: string;
    subtitle: string;
    icon: React.JSX.Element;
    tags: string[];
}
const groups: Group[] = [
    {
        title: "Golang",
        subtitle: "Go libraries",
        icon: <GolangIcon />,
        tags: ["golang", "go"]
    },
    {
        title: "JavaScript",
        subtitle: "Libraries to use with JavaScript or TypeScript",
        icon: <NodejsIcon />,
        tags: ["nodejs", "javascript", "typescript"]
    },
    {
        title: "C#",
        subtitle: "Libraries to use with C#",
        icon: <CsharpIcon />,
        tags: ["csharp"]
    },
    {
        title: "Gitlab",
        subtitle: "Gitlab-Komponenten, includes etc",
        icon: <GitlabIcon />,
        tags: ["gitlab"]
    },
    {
        title: "Docker",
        subtitle: "Docker base images",
        icon: <DockerIcon />,
        tags: ["docker"]
    }
]

const Body = () => {
    const catalogApi = useApi(catalogApiRef);

    const {
        value: entityGroups,
        loading,
        error
    } = useAsync(async () => {
        const response = await catalogApi.getEntities({
            filter: {
                kind: 'component',
                'spec.type': 'library'
            },
        });

        const namedGroups = groups.map(group => ({
            group: group,
            libraries: [] as ComponentEntity[]
        }))

        const restGroup = {
            group: {
                title: "Other",
                subtitle: "Other libraries",
                icon: <div>Other</div>,
            },
            libraries: [] as ComponentEntity[]
        }

        const components = response.items as ComponentEntity[]
        components.forEach(component => {
            const tags = component.metadata.tags ?? []
            const group = namedGroups.find(g => tags.some(tag => g.group.tags.includes(tag)))
            if (group) {
                group.libraries.push(component)
            } else {
                restGroup.libraries.push(component)
            }
        })
        return [...namedGroups, restGroup]

    }, [catalogApi])

    if (loading) {
        return <Progress/>;
    }

    if (error) {
        return (
            <WarningPanel severity="error" title="Could not load domains.">
                {error.message}
            </WarningPanel>
        );
    }

    if (!entityGroups?.length) {
        return (
            <EmptyState
                missing="info"
                title="No libraries available"
                description="There dont seem to be any libraries available for you"
            />
        );
    }

    return (
        <>
            {entityGroups
                .filter(group => group.libraries.length > 0)
                .map(group => (
                <Box mb={2}>
                    <ContentHeader title={group.group.title} description={group.group.subtitle} />

                    <ItemCardGrid>
                    {group.libraries.map(entity => (
                        <LibraryExplorerCard entity={entity}/>
                    ))}
                    </ItemCardGrid>
                </Box>
            ))}
        </>
    )
}

export const LibraryExplorerContent = (props: { title?: string }) => {
    return (
        <Content noPadding>
            <ContentHeader title={props.title ?? "Libraries"} description="Discover available libraries in our eco system">
                <SupportButton>
                    Dont see a library you made? Contact me and we will sort it out!
                </SupportButton>
            </ContentHeader>
            <Body/>
        </Content>
    )
}