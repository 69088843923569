import {
    ScmIntegrationsApi,
    scmIntegrationsApiRef,
    ScmAuth, scmAuthApiRef,
} from '@backstage/integration-react';
import {
    AnyApiFactory,
    configApiRef,
    createApiFactory, discoveryApiRef, errorApiRef, gitlabAuthApiRef, identityApiRef
} from '@backstage/core-plugin-api';
import {visitsApiRef, VisitsWebStorageApi} from "@backstage/plugin-home";
import {techInsightsApiRef, TechInsightsClient} from "@backstage-community/plugin-tech-insights";
import {ScoreRenderer} from "./components/scorecards/ScoreRenderer";

export const apis: AnyApiFactory[] = [
    createApiFactory({
        api: scmIntegrationsApiRef,
        deps: {configApi: configApiRef},
        factory: ({configApi}) => ScmIntegrationsApi.fromConfig(configApi),
    }),
    createApiFactory({
        api: visitsApiRef,
        deps: {
            identityApi: identityApiRef,
            errorApi: errorApiRef
        },
        factory: ({identityApi, errorApi}) => VisitsWebStorageApi.create({identityApi, errorApi}),
    }),
    createApiFactory({
        api: techInsightsApiRef,
        deps: {discoveryApi: discoveryApiRef, identityApi: identityApiRef},
        factory: ({discoveryApi, identityApi}) =>
            new TechInsightsClient({
                discoveryApi,
                identityApi,
                renderers: [
                    ScoreRenderer
                ]
            })
    }),
    createApiFactory({
        api: scmAuthApiRef,
        deps: {gitlabAuthApi: gitlabAuthApiRef},
        factory: ({gitlabAuthApi}) => ScmAuth.forGitlab(gitlabAuthApi)
    })
];
