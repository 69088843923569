import {Mermaid} from "backstage-plugin-techdocs-addon-mermaid";
import React from "react";
import {useTheme} from "@material-ui/core";

export const CustomMermaid = () => {

    const theme = useTheme();
    return (
        <Mermaid
            config={{
                theme: theme.palette.type === 'dark' ? 'dark' : 'default',
            }}
        />
    )
}