import React from "react";
import {CheckResultRenderer} from "@backstage-community/plugin-tech-insights";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {Tooltip} from "@material-ui/core";


export const CircularProgressWithLabel = (props: {value: number, label: string, comment: string}) =>(
    <Tooltip title={props.comment}>
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="static" value={props.value} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" component="div" color="textSecondary">{props.label}</Typography>
            </Box>
        </Box>
    </Tooltip>
)

export const ScoreRenderer: CheckResultRenderer = {
    type: 'score',
    component: ({ facts: { score: { value , metadata} }}) => {
        const v = isNaN(+value) ? 0 : (value as number)/6*100;
        const l = isNaN(+value) ? "N/A" : `${(value as number)}/6`;
        const comment = metadata?.comment;
        return <CircularProgressWithLabel value={v} label={l} comment={comment}/>;
    },
    description: ({check:{ description}}) => {
        return <a href={description} target="_blank">Docs</a>
    },
    isFailed: ({ facts: { score: { value} }}) => {
        return (value as number)<0.5;
    }

}
