import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import {
    EntityApiDefinitionCard,
    EntityConsumingComponentsCard,
    EntityProvidingComponentsCard,
} from '@backstage/plugin-api-docs';
import {
    EntityAboutCard,
    EntityDependsOnComponentsCard,
    EntityDependsOnResourcesCard,
    EntityHasSubcomponentsCard,
    EntityHasSystemsCard,
    EntityLayout,
    EntityLinksCard,
    EntityOrphanWarning,
    EntityProcessingErrorsPanel,
    EntityRelationWarning,
    EntitySwitch,
    hasCatalogProcessingErrors,
    hasRelationWarnings,
    isComponentType,
    isKind,
    isOrphan,
} from '@backstage/plugin-catalog';
import {
    EntityGroupProfileCard,
    EntityMembersListCard,
    EntityOwnershipCard,
    EntityUserProfileCard,
} from '@backstage/plugin-org';
import {EntityTechdocsContent} from '@backstage/plugin-techdocs';
import {Direction, EntityCatalogGraphCard,} from '@backstage/plugin-catalog-graph';
import {
    RELATION_API_CONSUMED_BY,
    RELATION_API_PROVIDED_BY,
    RELATION_CONSUMES_API,
    RELATION_DEPENDENCY_OF,
    RELATION_DEPENDS_ON,
    RELATION_HAS_PART,
    RELATION_PART_OF,
    RELATION_PROVIDES_API,
} from '@backstage/catalog-model';

import {TechDocsAddons} from '@backstage/plugin-techdocs-react';
import {ReportIssue} from '@backstage/plugin-techdocs-module-addons-contrib';
import {EntityTechInsightsScorecardCard} from "@backstage-community/plugin-tech-insights";
import {AppsTable} from "./AppsTable";
import {Link} from "@backstage/core-components";
import {AlertList} from "./grafana";
import {CustomMermaid} from "../techdocs/mermaid";

const techdocsContent = (
    <EntityTechdocsContent>
        <TechDocsAddons>
            <ReportIssue/>
            <CustomMermaid />
        </TechDocsAddons>
    </EntityTechdocsContent>
);

const entityWarningContent = (
    <>
        <EntitySwitch>
            <EntitySwitch.Case if={isOrphan}>
                <Grid item xs={12}>
                    <EntityOrphanWarning/>
                </Grid>
            </EntitySwitch.Case>
        </EntitySwitch>

        <EntitySwitch>
            <EntitySwitch.Case if={hasRelationWarnings}>
                <Grid item xs={12}>
                    <EntityRelationWarning/>
                </Grid>
            </EntitySwitch.Case>
        </EntitySwitch>

        <EntitySwitch>
            <EntitySwitch.Case if={hasCatalogProcessingErrors}>
                <Grid item xs={12}>
                    <EntityProcessingErrorsPanel/>
                </Grid>
            </EntitySwitch.Case>
        </EntitySwitch>
    </>
);

const overviewContent = (
    <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item container md={6}>
            <Grid item xs={12}>
                <EntityAboutCard variant="flex"/>
            </Grid>
        </Grid>

        <Grid item md={6}>
            <EntitySwitch>
                 <EntitySwitch.Case if={isComponentType('service')}>
                    <AlertList />
                 </EntitySwitch.Case>

                <EntitySwitch.Case if={isComponentType('app')}>
                    <EntityHasSubcomponentsCard title="Services" variant="gridItem"/>
                </EntitySwitch.Case>
            </EntitySwitch>

        </Grid>
    </Grid>
);

const testCard = (title: string, checks: string[]) => (
    <Grid item md={4}><EntityTechInsightsScorecardCard title={title} checksId={checks}/></Grid>
)
const scorecardsPage = (
    <Grid container spacing={3} alignItems="stretch" title="Quality Review">
        <Grid item xs={12}>
            <Typography variant="h3">Quality Review</Typography>
            <Typography variant="subtitle1">
                Various questions regarding the quality process of the component.
                Check out the <Link
                to="https://git.d-velop.de/dvelop/architecture/well-architected-app-review/-/blob/main/docs/QUALITY.md"
                target="_blank"> Quality Review Documentation</Link> for more information.
            </Typography>
        </Grid>
         {testCard("Functional test", [
            "quality/QF01", "quality/QF02", "quality/QF03" ])}
         {testCard("Performance test", [
            "quality/QP01", "quality/QP02", "quality/QP03" ])}
         {testCard("Cloud specific test", [
            "quality/QC01", "quality/QC02", "quality/QC03",
            "quality/QC04"])}
         {testCard("Regression test", [
            "quality/QR01", "quality/QR02", "quality/QR03",
            "quality/QR04"])}
         {testCard("Management", [
            "quality/QM01", "quality/QM02", "quality/QM03",
            "quality/QM04"])}
         {testCard("Onprem specific", ["quality/QO01"])}
        <Grid item xs={12}>
            <Typography variant="h3">Security Review</Typography>
            <Typography variant="subtitle1">
                Various questions regarding the security best practices of the component.
                Check out the <Link
                to="https://git.d-velop.de/dvelop/architecture/well-architected-app-review/-/blob/main/docs/SECURITY.md"
                target="_blank"> Security Review Documentation</Link> for more information.
            </Typography>
        </Grid>
         {testCard("Confidentiality", [
            "security/SC01", "security/SC02", "security/SC03",
            "security/SC04", "security/SC05", "security/SC06",
            "security/SC07", "security/SC08", "security/SC09",
            "security/SC10"])}
         {testCard("Integrity", [
            "security/SI01", "security/SI02", "security/SI03",
            "security/SI04", "security/SI05", "security/SI06",
            "security/SI07"])}
         {testCard("Availability", [
            "security/SA01", "security/SA02", "security/SA03a",
            "security/SA04", "security/SA05", "security/SA06",
            "security/SA07"])}
         {testCard("Data Protection", [
            "security/SD01", "security/SD02", "security/SD03",
            "security/SD04", "security/SD05", "security/SD06"])}

    </Grid>
)

const serviceEntityPage = (
    <EntityLayout>
        <EntityLayout.Route path="/" title="Overview">
            {overviewContent}
        </EntityLayout.Route>

        <EntityLayout.Route path="/tech-insights" title="Scorecards">
            {scorecardsPage}
        </EntityLayout.Route>

        {/* <EntityLayout.Route path="/api" title="API">*/}
        {/*     <Grid container spacing={3} alignItems="stretch">*/}
        {/*         <Grid item md={6}>*/}
        {/*             <EntityProvidedApisCard/>*/}
        {/*         </Grid>*/}
        {/*         <Grid item md={6}>*/}
        {/*             <EntityConsumedApisCard/>*/}
        {/*         </Grid>*/}
        {/*     </Grid>*/}
        {/* </EntityLayout.Route>*/}

        {/* <EntityLayout.Route path="/dependencies" title="Dependencies">*/}
        {/*     <Grid container spacing={3} alignItems="stretch">*/}
        {/*         <Grid item md={6}>*/}
        {/*             <EntityDependsOnComponentsCard variant="gridItem"/>*/}
        {/*         </Grid>*/}
        {/*         <Grid item md={6}>*/}
        {/*             <EntityDependsOnResourcesCard variant="gridItem"/>*/}
        {/*         </Grid>*/}
        {/*     </Grid>*/}
        {/* </EntityLayout.Route>*/}

        <EntityLayout.Route path="/docs" title="Docs">
            {techdocsContent}
        </EntityLayout.Route>
    </EntityLayout>
);

const websiteEntityPage = (
    <EntityLayout>
        <EntityLayout.Route path="/" title="Overview">
            {overviewContent}
        </EntityLayout.Route>

        <EntityLayout.Route path="/dependencies" title="Dependencies">
            <Grid container spacing={3} alignItems="stretch">
                <Grid item md={6}>
                    <EntityDependsOnComponentsCard variant="gridItem"/>
                </Grid>
                <Grid item md={6}>
                    <EntityDependsOnResourcesCard variant="gridItem"/>
                </Grid>
            </Grid>
        </EntityLayout.Route>

        <EntityLayout.Route path="/docs" title="Docs">
            {techdocsContent}
        </EntityLayout.Route>
    </EntityLayout>
);

/**
 * NOTE: This page is designed to work on small screens such as mobile devices.
 * This is based on Material UI Grid. If breakpoints are used, each grid item must set the `xs` prop to a column size or to `true`,
 * since this does not default. If no breakpoints are used, the items will equitably share the available space.
 * https://material-ui.com/components/grid/#basic-grid.
 */

const defaultEntityPage = (
    <EntityLayout>
        <EntityLayout.Route path="/" title="Overview">
            {overviewContent}
        </EntityLayout.Route>

        <EntityLayout.Route path="/docs" title="Docs">
            {techdocsContent}
        </EntityLayout.Route>
    </EntityLayout>
);

const componentPage = (
    <EntitySwitch>
        <EntitySwitch.Case if={isComponentType('service')}>
            {serviceEntityPage}
        </EntitySwitch.Case>

        <EntitySwitch.Case if={isComponentType('website')}>
            {websiteEntityPage}
        </EntitySwitch.Case>

        <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
    </EntitySwitch>
);

const apiPage = (
    <EntityLayout>
        <EntityLayout.Route path="/" title="Overview">
            <Grid container spacing={3}>
                {entityWarningContent}
                <Grid item md={6}>
                    <EntityAboutCard/>
                </Grid>
                <Grid item md={6} xs={12}>
                    <EntityCatalogGraphCard variant="gridItem" height={400}/>
                </Grid>
                <Grid item md={4} xs={12}>
                    <EntityLinksCard/>
                </Grid>
                <Grid container item md={12}>
                    <Grid item md={6}>
                        <EntityProvidingComponentsCard/>
                    </Grid>
                    <Grid item md={6}>
                        <EntityConsumingComponentsCard/>
                    </Grid>
                </Grid>
            </Grid>
        </EntityLayout.Route>

        <EntityLayout.Route path="/definition" title="Definition">
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <EntityApiDefinitionCard/>
                </Grid>
            </Grid>
        </EntityLayout.Route>
    </EntityLayout>
);

const userPage = (
    <EntityLayout>
        <EntityLayout.Route path="/" title="Overview">
            <Grid container spacing={3}>
                {entityWarningContent}
                <Grid item xs={12} md={6}>
                    <EntityUserProfileCard variant="gridItem" showLinks/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <EntityOwnershipCard variant="gridItem"/>
                </Grid>
            </Grid>
        </EntityLayout.Route>
    </EntityLayout>
);

const groupPage = (
    <EntityLayout>
        <EntityLayout.Route path="/" title="Overview">
            <Grid container spacing={3}>
                {entityWarningContent}
                <Grid item xs={12} md={6}>
                    <EntityGroupProfileCard variant="gridItem"/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <EntityOwnershipCard variant="gridItem"/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <EntityMembersListCard/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <EntityLinksCard/>
                </Grid>
            </Grid>
        </EntityLayout.Route>
    </EntityLayout>
);

const systemPage = (
    <EntityLayout>
        <EntityLayout.Route path="/" title="Overview">
            <Grid container spacing={3} alignItems="stretch">
                {entityWarningContent}
                <Grid item md={6}>
                    <EntityAboutCard variant="gridItem"/>
                </Grid>
                <Grid item md={6} xs={12}>
                    <AppsTable/>
                </Grid>
            </Grid>
        </EntityLayout.Route>
        <EntityLayout.Route path="/diagram" title="Diagram">
            <EntityCatalogGraphCard
                variant="gridItem"
                direction={Direction.TOP_BOTTOM}
                title="System Diagram"
                height={700}
                relations={[
                    RELATION_PART_OF,
                    RELATION_HAS_PART,
                    RELATION_API_CONSUMED_BY,
                    RELATION_API_PROVIDED_BY,
                    RELATION_CONSUMES_API,
                    RELATION_PROVIDES_API,
                    RELATION_DEPENDENCY_OF,
                    RELATION_DEPENDS_ON,
                ]}
                unidirectional={false}
            />
        </EntityLayout.Route>
    </EntityLayout>
);

const domainPage = (
    <EntityLayout>
        <EntityLayout.Route path="/" title="Overview">
            <Grid container spacing={3} alignItems="stretch">
                {entityWarningContent}
                <Grid item md={6}>
                    <EntityAboutCard variant="gridItem"/>
                </Grid>
                <Grid item md={6} xs={12}>
                    <EntityCatalogGraphCard variant="gridItem" height={400}/>
                </Grid>
                <Grid item md={6}>
                    <EntityHasSystemsCard variant="gridItem"/>
                </Grid>
            </Grid>
        </EntityLayout.Route>
    </EntityLayout>
);

export const entityPage = (
    <EntitySwitch>
        <EntitySwitch.Case if={isKind('component')} children={componentPage}/>
        <EntitySwitch.Case if={isKind('api')} children={apiPage}/>
        <EntitySwitch.Case if={isKind('group')} children={groupPage}/>
        <EntitySwitch.Case if={isKind('user')} children={userPage}/>
        <EntitySwitch.Case if={isKind('system')} children={systemPage}/>
        <EntitySwitch.Case if={isKind('domain')} children={domainPage}/>

        <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
    </EntitySwitch>
);
