import React from 'react';
import { makeStyles } from '@material-ui/core';
import DvelopLogo from "../../assets/icons/DvelopLogo";

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 30,
  },
  path: {
    fill: '#7df3e1',
  },
});
const LogoFull = () => {
  const classes = useStyles();

  return (
      <DvelopLogo classes={{ svg: classes.svg, path: classes.path }} />
  );
};

export default LogoFull;
