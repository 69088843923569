import React from "react";
import {
    Content,
    ContentHeader,
    EmptyState,
    ItemCardGrid,
    Progress,
    SupportButton,
    WarningPanel
} from "@backstage/core-components";
import {
    catalogApiRef,
} from "@backstage/plugin-catalog-react";
import {useApi} from "@backstage/core-plugin-api";
import {useAsync} from "react-use";
import {DomainEntity} from "@backstage/catalog-model";
import {SystemExplorerCard} from "./SystemExplorerCard";

const Body = () => {
    const catalogApi = useApi(catalogApiRef);

    const {
        value: entities,
        loading,
        error
    } = useAsync(async () => {
        const response = await catalogApi.getEntities({
            filter: {
                kind: 'system',
            },
        });
        return response.items as DomainEntity[]
    }, [catalogApi])

    if (loading) {
        return <Progress/>;
    }

    if (error) {
        return (
            <WarningPanel severity="error" title="Could not load domains.">
                {error.message}
            </WarningPanel>
        );
    }

    if (!entities?.length) {
        return (
            <EmptyState
                missing="info"
                title="No systems available"
                description="There dont seem to be any libraries available for you"
            />
        );
    }

    return (
        <ItemCardGrid>
            {entities.map(entity => (
                <SystemExplorerCard entity={entity}/>
            ))}
        </ItemCardGrid>
    )
}

export const SystemExplorerContent = (props: { title?: string }) => {
    return (
        <Content noPadding>
            <ContentHeader title={props.title ?? "Systems Explorer"}>
                <SupportButton>Discover the systems that make up the d.velop software landscape</SupportButton>
            </ContentHeader>
            <Body/>
        </Content>
    )
}