import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
  useSidebarOpenState,
  Link, SidebarSubmenu, SidebarSubmenuItem,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import CategoryIcon from '@material-ui/icons/Category';
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import {ExploreIcon} from "../../assets/icons/CustomIcons";

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home">
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarItem icon={HomeIcon} to="/" text="Home" />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SidebarSearchModal />
      </SidebarGroup>
      <SidebarDivider />
      <SidebarItem icon={ExploreIcon} text="Explore" to="explore"/>
      <SidebarItem icon={LibraryBooksIcon} to="docs" text="TechDocs" />

      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        <SidebarItem icon={CategoryIcon} text="Catalog" to="catalog">
          <SidebarSubmenu title="Catalog">
            <SidebarSubmenuItem title="Systems" to="catalog?filters[kind]=System"/>
            <SidebarSubmenuItem title="Apps" to="catalog?filters[kind]=Component&filters[type]=app"/>
            <SidebarSubmenuItem title="Services" to="catalog?filters[kind]=Component&filters[type]=service"/>
            <SidebarSubmenuItem title="Libraries" to="catalog?filters[kind]=Component&filters[type]=library"/>
            <SidebarDivider />
            <SidebarSubmenuItem title="All components" to="catalog"/>

          </SidebarSubmenu>
        </SidebarItem>

        <SidebarItem icon={CreateComponentIcon} to="create" text="Create..." />
      </SidebarGroup>

      <SidebarSpace />
      <SidebarDivider />
      <SidebarGroup label="Settings" icon={<UserSettingsSignInAvatar />} to="/settings">
        <SidebarSettings />
      </SidebarGroup>
    </Sidebar>
    {children}
  </SidebarPage>
);
