import React from "react";
import {
    ExploreLayout,
    ToolExplorerContent
} from "@backstage-community/plugin-explore";
import {LibraryExplorerContent} from "./LibraryExplorerContent";
import {SystemExplorerContent} from "./SystemExplorerContent";

export const ExplorePage = () => {
    return (
        <ExploreLayout
            title="Explore the d.velop ecosystem"
            subtitle="Explore tools, services and documentation we have available for you"
        >
            <ExploreLayout.Route path="systems" title="Systems">
                <SystemExplorerContent />
            </ExploreLayout.Route>

            <ExploreLayout.Route path="tools" title="Tools">
                <ToolExplorerContent />
            </ExploreLayout.Route>

            <ExploreLayout.Route path="libraries" title="Libraries">
                <LibraryExplorerContent />
            </ExploreLayout.Route>

        </ExploreLayout>
    )
}

export const explorePage = <ExplorePage />;